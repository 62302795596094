import React, { Fragment } from "react"
import GalleryBanner from "../components/gallery/galleryBanner"
import PortfoliosListContainer from "../components/gallery/portfoliosListContainer"
import Footer from "../components/common/footer"
import "semantic-ui-css/semantic.min.css"
import "../styles/index.css"
import { Helmet } from 'react-helmet';  // Import Helmet

const Portfolios = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Projects - DP Tile and Stone</title>
        <meta name="description" content="Explore our projects to see a showcase of our best work in design and creativity across various industries." />
      </Helmet>
      <GalleryBanner/>
      <PortfoliosListContainer />
      <Footer />
    </Fragment>
  )
}
export default Portfolios
