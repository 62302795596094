import React from "react";
import _ from "lodash";
import { Image, Card, Search, Pagination, Divider, List, Checkbox, Icon } from "semantic-ui-react";
import { Link } from "gatsby";
import { changeGalleryPage } from "../../state/app";
import { connect } from "react-redux";

const initialState = {
  isLoading: false,
  results: [],
  value: "",
  typeFilter: [],
  activePage: 1,
};

const typeList = ["Commercial", "Residential", "Public Space"];

class PortfoliosList extends React.Component {
  state = initialState;

  componentDidMount() {
    this.setState({ activePage: this.props.products.galleryPage });
  }

  handleResultSelect = (e, { result }) => this.setState({ value: result.title });

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState);

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = (result) => re.test(result.name);

      let filterPortfolios = _.filter(this.props.portfolios, isMatch).map((portfolio) => {
        return {
          title: portfolio.name,
          image: portfolio.coverImage.fluid.src,
        };
      });
      this.setState({
        isLoading: false,
        results: filterPortfolios,
      });
    }, 300);
  };

  typeFilterToggle = (item) => {
    let typeFilter = this.state.typeFilter;
    let index = typeFilter.indexOf(item);
    if (index !== -1) {
      typeFilter.splice(index, 1);
    } else {
      typeFilter.push(item);
    }
    this.setState({ typeFilter });
  };

  async handleChangePagination(event, data) {
    await this.setState({
      activePage: data.activePage,
    });
  }

  render() {
    const { isLoading, value, results, typeFilter, activePage } = this.state;

    let filterPortfolios = this.props.portfolios;
    if (typeFilter.length) {
      filterPortfolios = filterPortfolios.filter((portfolio) => {
        if (portfolio.typeTags) {
          if (portfolio.typeTags.some((r) => typeFilter.includes(r))) {
            return portfolio;
          } else {
            return null;
          }
        } else return null;
      });
    }

    if (value) {
      filterPortfolios = filterPortfolios.filter((portfolio) => {
        if (portfolio.name) {
          if (portfolio.name === value) {
            return portfolio;
          } else {
            return null;
          }
        } else return null;
      });
    }

    const portfolios = filterPortfolios.slice(activePage * 9 - 9, activePage * 9);

    return (
      <div className="portfolios-list-container">
        <div className="filter-section">
          {/* <Search
            input={{ iconPosition: "left" }}
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
            results={results}
            value={value}
            placeholder="Search"
          />
          <Divider /> */}
          <div className="search-project">
            <p>Type</p>
            <List>
              {typeList.map((type) => (
                <List.Item key={type}>
                  <Checkbox
                    label={type}
                    onChange={() => this.typeFilterToggle(type)}
                    checked={typeFilter.includes(type)}
                  />
                </List.Item>
              ))}
            </List>
          </div>
        </div>
        <div className="cards-section">
          <Card.Group>
            {portfolios.map((portfolio) => {
              const link = `/portfolios/${portfolio.slug}`;
              return (
                <Link to={link} key={portfolio.slug} className="card-link custom-card">
                  <Card>
                    <Image src={portfolio.coverImage.fluid.src} wrapped ui={false} className="coverImage" />
                    <Card.Content>
                      <Card.Header>{portfolio.name}</Card.Header>
                    </Card.Content>
                  </Card>
                </Link>
              );
            })}
          </Card.Group>
          <div className="paginationContainer">
            <Pagination
              boundaryRange={1}
              activePage={activePage}
              ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
              firstItem={null}
              lastItem={null}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              siblingRange={1}
              totalPages={Math.ceil(this.props.portfolios.length / 9)}
              onPageChange={(event, data) => this.handleChangePagination(event, data)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ products }) => ({ products }), { changeGalleryPage })(PortfoliosList);
