import React from "react"
import { Segment, Header } from "semantic-ui-react"
import { StaticQuery, graphql } from "gatsby"
import HeaderComponent from "../common/header"

const GalleryBanner = () => (
  <StaticQuery
    query={graphql`
      query GalleryPageBannerQuery {
        contentfulOtherPages {
          galleryPageBannerImage {
            fluid(maxWidth: 4000,quality:100) {
              src
            }
          }
        }
      }
    `}
    render={data => {
      const galleryPage = data.contentfulOtherPages

      return (
        <Segment
          inverted
          textAlign="center"
          vertical
          style={{
            background: `url(${galleryPage.galleryPageBannerImage.fluid.src}) no-repeat center center`,
            backgroundSize: "cover",
            height: "350px",
          }}
          className="productsPage-banner"
        >
          <HeaderComponent page="gallery" />
          <Header as="h1" content="Projects" inverted />
        </Segment>
      )
    }}
  />
)

export default GalleryBanner
