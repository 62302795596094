import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PortfoliosList from "./portfoliosList"

const PortfoliosListContainer = () => (
  <StaticQuery
    query={graphql`
      query PortfoliosListQuery {
        allContentfulPortfolios(sort: { fields: createdAt, order: DESC }) {
          nodes {
            slug
            name
            typeTags
            coverImage {
              fluid(maxWidth: 4000, quality: 100) {
                src
              }
            }
          }
        }
      }
    `}
    render={data => {
      const portfolios = data.allContentfulPortfolios.nodes

      return (
        <div className="homepage">
          <PortfoliosList portfolios={portfolios} />
        </div>
      )
    }}
  />
)

export default PortfoliosListContainer
